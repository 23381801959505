<template>
    <div>
        <h3>Trade Accounts</h3>
        <psi-simple-table
            :items="tradeAccounts"
            :headers="headers"
        ></psi-simple-table>
    </div>
</template>
<script>
export default {
    name: "credit-trade-accounts",
    components: {},
    props: {
        trades: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        tradeAccounts() {
            return this.trades;
        },
        headers() {
            return Object.keys(this.trades[0])
                .map((key) => {
                    const { title, order } = this.knownTitles(key);
                    return {
                        title,
                        class: "text-left",
                        order,
                        key,
                        format: ["amountPastDue", "balance"].includes(key)
                            ? "currency"
                            : "",
                    };
                })
                .sort((a, b) => a.order > b.order);
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        knownTitles(key) {
            switch (key) {
                case "accountType":
                    return {
                        title: "Account Type",
                        order: 2,
                    };
                case "amountPastDue":
                    return {
                        title: "Amount Past Due",
                        order: 5,
                    };
                case "balance":
                    return {
                        title: "Balance",
                        order: 6,
                    };
                case "currentMannerOfPayment":
                    return {
                        title: "Manner of Payment",
                        order: 7,
                    };
                case "dateClosed":
                    return {
                        title: "Date Closed",
                        order: 1,
                    };
                case "loanType":
                    return {
                        title: "Loan Type",
                        order: 3,
                    };
                case "subscriberName":
                    return {
                        title: "Subscriber Name",
                        order: 4,
                    };
            }
        },
    },
};
</script>

<style scoped>
</style>